<template>
   <div id="portal-app" class="container-fluid">
      <SplittedLayout />
   </div>
</template>

<script>
export default {
   name: 'ObiThemePreview',
   props: {},
   data() {
      return {
         mode: 'light',
      };
   },
};
</script>
<style lang="scss" scoped>
#portal-app {
   margin: 0;
   padding: 0;
   font-family: sans-serif !important;
   height: 100%;
   max-width: 400px;
}
</style>
